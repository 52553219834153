import { createContext } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export const AdContext = createContext();

const AdContextProvider = (props) => {
  // States
  const [homePageAds, setHomePageAds] = useState([]);
  const [articleReadPageAds, setArticleReadPageAds] = useState([]);

  //  Fetch Functions
  const fetchHomePageAds = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/filterPage?page=home-page&country=GH&platform=sportdm`,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setHomePageAds(res.data.data);
      })
      .then((err) => {
        return;
      });
  };

  //  Fetch Functions
  const fetchArticleReadPageAds = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/filterPage?page=article-read-page&country=GH&platform=sportdm`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setArticleReadPageAds(res.data.data);
      })
      .then((err) => {
        return;
      });
  };

  // Effects
  useEffect(() => {
    fetchHomePageAds();
    fetchArticleReadPageAds();
  }, []);

  return (
    <AdContext.Provider value={{ homePageAds, articleReadPageAds }}>
      {props.children}
    </AdContext.Provider>
  );
};

export default AdContextProvider;
