import classes from "./ArticleContainer.module.css";
import { faArrowLeft, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import { AppContext } from "../../Context/AppContext";
import moment from "moment/moment";
import MoreLikeThisContainer from "../MoreLikeThisContainer/MoreLikeThisContainer";
import AdComponent from "../../Components/AdComponent/AdComponent";
import RelatedArticles from "../RelatedArticles/RelatedArticles";
import { CircularProgress } from "@mui/material";
import { scrollToTop } from "../../Utilities/scrollToTop";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useState } from "react";
import axios from "axios";
import { AdContext } from "../../Context/AdContext";

// Articles

const ArticleContainer = () => {
  // Context
  const {
    fetchSelectedArticle,
    selectedArticle,
    setSearchQuery,
    searchHandler,
    getCreatedNewsById,
    isSendingRequest,
  } = useContext(AppContext);

  const { articleReadPageAds } = useContext(AdContext);

  // States
  const [moreLikeThis, setMoreLikeThis] = useState([]);

  //   Params and Routing
  const { articleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isCreated = location.pathname.includes("created-for-you");

  // Effect
  useEffect(() => {
    if (isCreated) {
      getCreatedNewsById(articleId);
    } else {
      fetchSelectedArticle(articleId);
    }

    // eslint-disable-next-line
  }, [articleId]);

  // Share functions

  // Twitter
  function shareOnTwitter() {
    const navUrl = !isCreated
      ? `https://twitter.com/intent/tweet?text=https://sportdm.com/home/${articleId}/${selectedArticle?.headline
          ?.replaceAll(" ", "-")
          ?.toLowerCase()}`
      : `https://twitter.com/intent/tweet?text=https://sportdm.com/home/created-for-you/${articleId}/${selectedArticle?.slug}`;
    window.open(navUrl, "_blank");
  }

  // Facebook

  function shareOnFacebook() {
    const navUrl = !isCreated
      ? `https://www.facebook.com/sharer/sharer.php?u=https://sportdm.com/home/${articleId}/${selectedArticle?.headline
          ?.replaceAll(" ", "-")
          ?.toLowerCase()}`
      : `https://www.facebook.com/sharer/sharer.php?u=https://sportdm.com/home/created-for-you/${articleId}/${selectedArticle?.slug}`;
    window.open(navUrl, "_blank");
  }

  function copyFunction() {
    var copyText = !isCreated
      ? `https://sportdm.com/home/${articleId}/${selectedArticle?.headline
          ?.replaceAll(" ", "-")
          ?.toLowerCase()}`
      : `https://sportdm.com/home/created-for-you/${articleId}/${selectedArticle?.slug}`;
    navigator.clipboard.writeText(copyText).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  let tags = [];

  if (selectedArticle) {
    tags = selectedArticle?.subject
      ?.filter((data) => {
        return data.profile === "tag";
      })
      ?.map((data) => {
        return data?.code;
      });
  }

  useEffect(() => {
    if (tags && !isCreated) {
      const subjectModifiedTags = tags?.map((tag) => {
        return `${tag}`;
      });

      const joinedTags = subjectModifiedTags
        .slice(0, 1)
        .join(",")
        .replaceAll("tag:", "");

      setMoreLikeThis([]);
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/api/v1/getNewsByTag?tag=${joinedTags}`
        )
        .then((res) => {
          const filteredToNotBeSame = res?.data?.data.filter((data) => {
            return data.uri !== articleId;
          });
          setMoreLikeThis(filteredToNotBeSame);
        })
        .catch((error) => {
          return;
        });
    }

    // eslint-disable-next-line
  }, [selectedArticle]);

  const [paragraph, setParagraph] = useState([]);

  // useEffect(() => {
  //   // if (selectedArticle) {
  //   //   console.log(selectedArticle.body_html);
  //   // }

  //   if (isCreated && selectedArticle) {
  //     const test = selectedArticle.full_description.split(
  //       /(<\/?(?:p|h[1-6]|div)>)|(<\/?(?:p|h[1-6]|div)>)/
  //     );

  //     const paragraphs = test.filter(Boolean);

  //     let newSplitPara = paragraphs.slice(0, 3);
  //     const images =
  //       selectedArticle.image.slice.length > 1 &&
  //       selectedArticle?.image.slice(1).map((data) => {
  //         return `<figure className=${classes.imagesSection} style="max-width: 100%; border: 2px solid red;" ><img style="width: 100%; height: auto;"  src="${data}" srcset="${data}" alt="Image" data-title="${selectedArticle?.title}" /><figcaption>${selectedArticle?.title}</figcaption></figure>`;
  //       });
  //     // newSplitPara.push(images);
  //     let newPara = newSplitPara.concat(newSplitPara, images);

  //     const lastPara = newPara.concat(newPara);

  //     console.log(newPara, images, lastPara);
  //     setParagraph(lastPara.join(""));
  //   }

  //   // eslint-disable-next-line
  // }, [selectedArticle]);

  useEffect(() => {
    if (isCreated && selectedArticle) {
      const test = selectedArticle.full_description?.split(
        /(<\/?(?:p|h[1-6]|div)>)|(<\/?(?:p|h[1-6]|div)>)/
      );

      const paragraphs = test?.filter(Boolean);

      let newSplitPara = paragraphs.slice(0, 14);

      const images =
        selectedArticle.image.length > 1 &&
        selectedArticle.image.slice(1).map((data) => {
          return `<figure className=${classes.imagesSection} style="max-width: 100%; " ><img style="width: 100%; height: auto;"  src="${data}" srcset="${data}" alt="Image" /><figcaption></figcaption></figure>`;
        });

      let lastPara = newSplitPara.concat(images);

      if (paragraphs.length > 14) {
        lastPara = lastPara.concat(paragraphs.slice(14));
      }

      setParagraph(lastPara.join(""));
    }

    // eslint-disable-next-line
  }, [selectedArticle]);

  return (
    <Layout>
      {selectedArticle && !isSendingRequest ? (
        <section className={classes.container}>
          <div className={classes.leftSection}>
            <div className={classes.adContainer}>
              <AdComponent
                adArray={articleReadPageAds}
                section="970px-by-250px-at-the-top-of-the-articles-page-just-before-the-news-content"
              />
            </div>
            <div
              className={classes.nav}
              onClick={() => {
                navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>

            <div className={classes.headline}>
              {selectedArticle?.headline || selectedArticle?.title}
            </div>
            <div className={classes.byLineAndShareSection}>
              <div>
                {"By SportsDM"} •{" "}
                {moment(selectedArticle?.updated_at).fromNow() || "minutes ago"}
              </div>
              <div>
                <span onClick={copyFunction}>
                  <FontAwesomeIcon icon={faLink} />
                </span>
                <span>
                  <FacebookIcon
                    style={{
                      width: "25px",
                      height: "25px",
                      background: "#000000",
                    }}
                    color="black"
                    onClick={shareOnFacebook}
                  />
                </span>

                <span>
                  <TwitterIcon
                    style={{
                      width: "25px",
                      height: "25px",
                      background: "#000000",
                    }}
                    color="black"
                    onClick={shareOnTwitter}
                  />
                </span>
              </div>
            </div>

            <div className={classes.imageSection}>
              <img
                src={
                  selectedArticle?.picture ||
                  selectedArticle?.associations?.featureimage?.renditions["1x1"]
                    ?.href ||
                  selectedArticle?.image[0]
                }
                alt={selectedArticle?.headline || selectedArticle?.headline}
              />
            </div>
            <div className={classes.tagSection}>
              {selectedArticle?.subject &&
                selectedArticle?.subject
                  ?.filter((data) => {
                    return data.profile === "tag";
                  })
                  ?.map((data) => {
                    return (
                      <div
                        key={data.code}
                        className={classes.key}
                        onClick={() => {
                          setSearchQuery(
                            data.name.replace(" ", "-").toLowerCase()
                          );
                          navigate("/search");
                          searchHandler();
                          scrollToTop();
                        }}
                      >
                        {data?.name.toUpperCase()}
                      </div>
                    );
                  })}
            </div>

            {!isCreated ? (
              <div
                className={classes.textBody}
                dangerouslySetInnerHTML={{
                  __html:
                    selectedArticle?.body_html ||
                    selectedArticle?.full_description,
                }}
              ></div>
            ) : (
              <div
                className={classes.textBody}
                dangerouslySetInnerHTML={{
                  __html: paragraph,
                }}
              ></div>
            )}

            <div className={classes.adSection}>
              <AdComponent
                adArray={articleReadPageAds}
                section="728px-by-90px-at-the-end-of-the-news,-just-before-related-articles-section"
              />
            </div>

            {!isCreated ? (
              <div className={classes.moreLikeThis}>
                <MoreLikeThisContainer
                  moreLikeThis={moreLikeThis.slice(0, 3)}
                />
              </div>
            ) : null}
          </div>
          <div className={classes.rightSection}>
            {moreLikeThis.length > 3 && !isCreated && (
              <div className={classes.relatedArticles}>
                <RelatedArticles moreLikeThis={moreLikeThis.slice(3, 7)} />
              </div>
            )}
            <div className={classes.adSection}>
              <AdComponent
                adArray={articleReadPageAds}
                section="300px-by-250px-the-first"
              />
            </div>

            {moreLikeThis.length > 7 && !isCreated && (
              <div className={classes.relatedArticles}>
                <RelatedArticles moreLikeThis={moreLikeThis.slice(8, 12)} />
              </div>
            )}

            <div className={classes.adSection}>
              <AdComponent
                adArray={articleReadPageAds}
                section="300px-by-250px-the-second"
              />
            </div>

            {moreLikeThis.length > 12 && !isCreated && (
              <div className={classes.relatedArticles}>
                <RelatedArticles moreLikeThis={moreLikeThis.slice(12, 16)} />
              </div>
            )}

            <div className={classes.adSection}>
              <AdComponent
                adArray={articleReadPageAds}
                section="120px-by-600px-the-after-the-third-related-articles-section"
              />
            </div>

            {moreLikeThis.length > 16 && !isCreated && (
              <div className={classes.relatedArticles}>
                <RelatedArticles moreLikeThis={moreLikeThis.slice(16, 20)} />
              </div>
            )}
          </div>
        </section>
      ) : isSendingRequest && !selectedArticle ? (
        <div className={classes.loading}>
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ color: "#ffd91b" }}
          />
        </div>
      ) : (
        <div className={classes.noArticle}>No Article</div>
      )}
    </Layout>
  );
};

export default ArticleContainer;
